import React from 'react';
import {NextSeo} from 'next-seo';

/* Components */
import {getLayout} from '@components/PageLayouts/Layout/Standard';
import LoginPage from '@scenes/LoginPage';

/* Constants */
import {PAGE_TYPE_LOGIN} from '@constants/pages';

/* Types */
import {Children} from 'types/base.types';

function LoginPageScene(): Children {
  return (
    <>
      <NextSeo title="Log In" />
      <LoginPage />
    </>
  );
}

LoginPageScene.pageAttributes = {page: PAGE_TYPE_LOGIN};
LoginPageScene.Layout = getLayout({promo: true, footer: false});

export default LoginPageScene;
