import React from 'react';
import {useRouter} from 'next/router';

/* Components */
import LoginForm from './LoginForm';

/*
  Returning users will use this page to login and redeem their API order. We need to
  load the api_order_redemption_flow split here so that the action/function that redirects
  users after login can use the split info. -GH Sep 1, 2020

  TODO: Add this in the future
*/
const LoginPage = () => {
  const {
    query: {email = ''},
  } = useRouter();

  return <LoginForm initialEmail={email as string} />;
};

export default LoginPage;
