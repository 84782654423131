import React from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {Button, InputField, Form, Grid, BUTTON_THEMES} from 'ht-styleguide';
import {SubmitHandler, useForm} from 'react-hook-form';

/* Queries */
import useUserMutationLogin from '@store/queries/user/useUserMutation.login';

/* Client State */
import {useAppStoreActions} from '@store/client/store.app';

/* Constants */
import {railsAdminPath, resetPasswordPath, clientsRegistrationPath} from '@constants/paths';
import {YupSchemaResolver} from '@constants/validations';

/* Styles */
import styles from './loginform.module.scss';

type LoginFormProps = {
  clientEmail?: string;
  initialEmail?: string;
  minimalView?: boolean;
  onForgotPasswordClick?: BaseAnyFunction;
  onSuccessCallback?: BaseAnyFunction;
  modalView?: boolean;
};

export type YupFormProps = {email: string; password: string};

const LoginForm = ({
  clientEmail,
  initialEmail,
  minimalView = false,
  onForgotPasswordClick = () => {},
  onSuccessCallback = () => {},
  modalView = false,
}: LoginFormProps) => {
  /* Mutations & Queries */
  const {data, mutate} = useUserMutationLogin();

  /* Hooks */
  const router = useRouter();
  const {setAppLevelLoading} = useAppStoreActions();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<YupFormProps>({
    ...YupSchemaResolver(['email', 'password'], undefined, {
      email: clientEmail || initialEmail || '',
      password: '',
    }),
  });

  /* Constants & Methods */
  const loginFormAttributes: SubmitHandler<YupFormProps> = async ({email, password}) => {
    mutate({email, password, callback: onSuccessCallback});

    setAppLevelLoading(true);

    return false;
  };

  const handleForgotPassword = () => (minimalView ? onForgotPasswordClick() : router.push(resetPasswordPath()));

  return (
    <Grid.Fluid classes={`site-v2 paddingY-medium1 ${styles.page}`} dataTestId="loginForm">
      <Grid.Row>
        <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={3} lg={6}>
          <div className="flex justifyContentSpaceBetween alignItemsCenter">
            <h1>Sign In</h1>
            {!minimalView && (
              <>
                {!clientEmail && (
                  <a data-testid="link-tech-partner-login" href={railsAdminPath()} className="medium-link text-align-right">
                    Sign in as Tech/Partner
                  </a>
                )}
              </>
            )}
          </div>
          <Form dataTestId="loginForm" classes="paddingTop-small2" onSubmit={() => false}>
            <Form.Row>
              <Form.Column>
                <InputField dataTestId="loginForm-email" type="email" label="Email Address" error={errors.email?.message} {...register('email')} />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <InputField
                  dataTestId="loginForm-password"
                  label="Password"
                  type="password"
                  error={errors.password?.message || data?.error}
                  {...register('password')}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Button dataTestId="loginForm-submit-button" theme={BUTTON_THEMES.V2PRIMARY} onClick={handleSubmit(loginFormAttributes)}>
                  Sign In
                </Button>
              </Form.Column>
            </Form.Row>
          </Form>
          <div className="marginTop-small2 flex justifyContentSpaceBetween">
            <span>
              {/* <Link href={minimalView ? '' : resetPasswordPath()} onClick={minimalView ? onForgetPasswordHandle : () => {}}> */}
              <a className="medium-link text-weight-medium" onClick={handleForgotPassword}>
                Forgot password?
              </a>
              {/* </Link> */}
            </span>
            {!minimalView && (
              <span>
                <Link href={clientsRegistrationPath()} className="medium-link text-weight-medium">
                  Register
                </Link>
              </span>
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

export default LoginForm;
