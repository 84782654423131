import {STATUSES} from 'types/component.types';

/* App Stores: Snack */
import {useSnackStoreActions} from '@store/client/store.snack';

const useNotice = () => {
  const {addErrorAppSnackNotice, addSuccessAppSnackNotice, addAppSnackNotice} = useSnackStoreActions();

  return {
    error(message: string, id?: string) {
      addErrorAppSnackNotice({content: message, id});
    },
    success(message: string, id?: string) {
      addSuccessAppSnackNotice({content: message, id});
    },
    info(message: string, id?: string) {
      addAppSnackNotice({status: STATUSES.info, id, content: message, dismissable: false});
    },
  };
};

export default useNotice;
