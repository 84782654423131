import {useMutation} from '@tanstack/react-query';
import {cart} from '@api/apis';
import {queryClient} from '@integrations/reactquery';

/* Keys */
import {cartQueryKeys} from './queries.keys';

/* Utils */
import {Logger} from '@utils/logger';

/* Hooks */
import useNotice from '@hooks/useNotice';

const useCartMutationFind = () => {
  const {error} = useNotice();

  return useMutation({
    mutationFn: async () => {
      const response = await cart.find({breakdown: true});
      if (response.data.status === 500 || response.err) throw response;

      return response.data;
    },
    onSuccess: response => {
      const {cart: cartResponse = {}} = response ?? {};
      queryClient.setQueryData(cartQueryKeys.all, {...cartResponse});
    },

    onError: data => {
      error('Error: We could not generate your cart');

      Logger('useCartMutation.find')(data as Error);
    },
  });
};

export default useCartMutationFind;
