import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useRouter} from 'next/router';

/* Apis */
import {user} from '@api/apis';

/* utils */
import {Logger} from '@utils/logger';
import {noop} from '@utils/event';
import {updateContingencies} from '@store/queries/user/user.utils';
import {removePlanSubscriberPhone} from '@scenes/LoginPage/login.cookies';
import {setUserPlanBrandingAndCookies} from '@integrations/partners/branding/branding.utils';

/* App Navigation Store */
import {updateAppNotification} from '@store/client/store.notifications';
import {cartQueryKeys} from '@store/queries/cart/queries.keys';
import useCartMutationFind from '@store/queries/cart/useCartMutation.find';
import {useAppStoreActions} from '@store/client/store.app';

/* Constants */
import {accountPath, completeOrderPath} from '@constants/paths';

import {Cart} from 'types/cart.types';

type UseLoginProp = {
  email: string;
  password: string;
  callback?: (arg: any) => void;
};

const GENERIC_LOGIN_API_ERROR_MESSAGE = 'Invalid email or password. Please try again.';

export default function useUserMutationLogin() {
  const queryClient = useQueryClient();
  const {mutate: findCartMutate} = useCartMutationFind();
  const router = useRouter();
  const {setOpenSlideModalLogin} = useAppStoreActions();

  return useMutation({
    mutationFn: async ({email, password, callback = noop}: UseLoginProp) => {
      let apiResponse;

      const {data} = await user.login({type: 'Client', password, email});
      apiResponse = data;

      if (data.status === 500) {
        throw new Error('Sorry, we cannot process your request. Please try again later.');
      }

      if (data.errors || data.err) {
        return {error: GENERIC_LOGIN_API_ERROR_MESSAGE};
      }

      if (apiResponse?.auth_token) {
        /* This drops the cookie: silly workaround for dev: diff ports */
        const response = await fetch(`/api/_nextassets_/current?auth_token=${data.auth_token}`);
        apiResponse = await response.json();
      }

      return apiResponse?.user ? apiResponse.user : apiResponse;
    },
    /* Update user */
    onSuccess: async (userResp, {callback}) => {
      const {authToken} = userResp ?? {};
      let path = accountPath();

      if (authToken) {
        await updateContingencies(userResp);

        /* Remove the plan subscription cookie */
        removePlanSubscriberPhone();

        /* Set plan branded user */
        setUserPlanBrandingAndCookies(userResp);

        /* ***** Get a cart if its associated with the user *****  */
        /* Invalidate existing */
        queryClient.invalidateQueries({queryKey: cartQueryKeys.all});
        /* Get an updated Cart from user being logged in */
        findCartMutate(undefined, {
          onSuccess: (response: {cart: Cart}) => {
            /* ***** Let apply the redirects ***** */
            const {cart} = response;

            /* A. 1. Api Order in progress? */
            if (cart && cart.order?.fromApi) {
              const queryParams = router.query;

              const queryString = Object.entries(queryParams)
                .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
                .join('&');
              /* 2. Forward them to another URL */
              path = `${completeOrderPath()}?${queryString}`;
            }

            /* B Route to either myAccount or Cart */
            /* 1. Do we have a cart or plan carted */
            if (cart && (cart.items?.length || cart.plan?.id)) {
              /* There are a lot of redirect stataus paths. Lets just keep it simple for now. */
              path = accountPath();
            }

            callback?.(userResp);
            router.push(path);
            setOpenSlideModalLogin(false);
          },
        });
      }
    },
    onError: (error: Error) => {
      updateAppNotification({error_title: 'Login Error', errors: [error.message]});

      Logger('useUserMutationLogin')(error as Error);
    },
  });
}
